import React from 'react'
import Layout from '../components/layout'

import bg from '../images/eat_fit_abq_contact.png'

const Contact = () => (
  <Layout>
    <img
      style={{ position: 'absolute', right: 10, top: 10 }}
      src={bg}
      alt="background"
    />
    <div
      className="header"
      style={{
        position: 'absolute',
        left: 250,
        top: 40,
      }}
    >
      Contact Us
    </div>
    <div
      style={{
        width: 315,
        fontFamily: 'Verdana, Geneva, sans-serif',
        fontSize: 14,
        position: 'absolute',
        top: 83,
        left: 250,
      }}
    >
      <p>
        Nohime Dennisson
        <br />
        Owner
        <br />
        <a href="mailto:eatfitabq@gmail.com" className="greentext">
        eatfitabq@gmail.com
        </a>
      </p>
    </div>
  </Layout>
)

export default Contact
